import React, { useContext, useEffect, useState } from "react";
import moment from "moment";
import { isEqual, isEmpty } from "lodash";
import { useHistory, useParams } from "react-router-dom";
import { Aloading } from "../../components/atoms/Aloading";
import DynamicBreadcrumb from "../../components/breadcrumb/DynamicBreadcrumb";
import {
  useFetchBnibTransaction,
  useFetchBnibTransactionProcess,
  useMutatePrintShippingLabel,
  useMutateReadyForLegitCheck,
  useMutateReadyShippingToBuyer,
  useMutateReceipt,
} from "../../hooks/bnib_transaction";
import {
  Box,
  Divider,
  Step,
  StepContent,
  StepLabel,
  Stepper,
} from "@material-ui/core";
import Abutton from "../../components/atoms/Abutton";
import OdisputeBnibTransaction from "../../components/organisms/bnib_transaction/OdisputeBnibTransaction";
import NavDetail from "../../components/navbar/NavDetail";
import OacceptBnibTransaction from "../../components/organisms/bnib_transaction/OacceptBnibTransaction";
import OrejectBnibTransaction from "../../components/organisms/bnib_transaction/OrejectBnibTransaction";
import OdefectBnibTransaction from "../../components/organisms/bnib_transaction/OdefectBnibTransaction";
import NewFilePonds from "../../components/addImage/NewFilepond";
import ErrorHandler from "../../components/toast/ErrorHandler";
import {
  useFetchLegitCheck,
  useMutateCreateLegitCheck,
  useMutateUpdateLegitCheck,
} from "../../hooks/legit_check";
import OcreateLegitCheckDetail from "../../components/organisms/bnib_transaction/OcreateLegitCheckDetail";
import { formatRupiah } from "../../helpers/common";
import OgiveResultLegitCheck from "../../components/organisms/bnib_transaction/OgiveResultLegitCheck";
import OgenerateShippingLabel from "../../components/organisms/bnib_transaction/OgenerateShippingLabel";
import ATextField from "../../components/atoms/AtextField";
import { ActionType } from "../../store/transaction/action";
import TransactionContext from "../../store/transaction/TransactionContext";
import OtrackProduct from "../../components/organisms/bnib_transaction/OtrackProduct";
import { EBnibTransactionStatus } from "../../core/bnib_transaction/entities";
import OprintLCQR from "../../components/organisms/bnib_transaction/OprintLCQR";
import Input from "../../components/input/Input";

const transactionRoute = (code: string) => {
  return [
    {
      id: 1,
      path: "/marketplace/transaction",
      name: "Transaction",
    },
    {
      id: 2,
      path: `/marketplace/transaction/${code}`,
      name: "Details",
    },
    {
      id: 3,
      path: `/marketplace/transaction/${code}/process`,
      name: "Transaction Process",
    },
  ];
};

const pattern = new RegExp(/^stock-move/);

export const renderStatusMessage = (status: string) => {
  if (pattern.test(status)) {
    return "Stock Move";
  }
  const statusMessage: any = {
    "waiting seller input track":
      "Waiting for Seller to Send The Packages to DEPATU",
    "seller input track": "Seller Input Tracking Code",
    arrived: "Packages Arrived at Mdn - DEPATU Berastagi",
    disputed: "Canceled (Product not sent)",
    accepted: "Product Correct",
    rejected: "Canceled (Product doesn’t same)",
    "defect true": "Product Defect",
    "defect false": "Product Not Defect",
    "ready for legit check": "Ready for LC",
    "legit check authentic": "Product Authentic",
    "legit check fake": "Canceled (Product Fake)",
    "legit check indefinable": "Canceled (Product Indefinable)",
    "ready shipping for buyer": "Ready for Shipping (Customer)",
    "depatu send": "On Shipping (Customer)",
    "stock move 1": "Stock Move",
  };
  if (statusMessage[status]) return statusMessage[status];
  return status.split("-").join(" ");
};

const TransactionProcess = () => {
  const history = useHistory();
  const params: { code: string } = useParams();
  const { TransactionDispatch, TransactionState } =
    useContext(TransactionContext);
  const { openTrackDialog } = TransactionState;
  const { data, isLoading } = useFetchBnibTransactionProcess(params?.code);
  const { data: dataTransaction, isLoading: LoadTransaction } =
    useFetchBnibTransaction(params?.code);
  const { data: dataLC, isLoading: loadLC } = useFetchLegitCheck(
    dataTransaction?.legit_check?.id || 0
  );

  const [newData, setNewData] = useState<any>(data);
  const [activeStep, setActiveStep] = useState(1);
  const [isDefect, setIsDefect] = useState<boolean>(false);
  const [defectImage, setDefectImage] = useState<any>(null);
  const [lcImage, setLcImage] = useState<{ file?: any[] | any }>({
    file: [],
  });
  const [correctImage, setCorrectImage] = useState<{ file?: any[] | any }>({
    file: [],
  });
  const [lcDetailId, setLcDetailId] = useState(0);
  const [lcDetailType, setLcDetailType] = useState("add");

  // const [openDisputeDialog, setOpenDisputeDialog] = useState(false);
  // const [openAcceptDialog, setOpenAcceptDialog] = useState(false);
  // const [openRejectDialog, setOpenRejectDialog] = useState(false);
  const [openDefectDialog, setOpenDefectDialog] = useState(false);
  const [openAddLcDetailDialog, setOpenAddLcDetailDialog] = useState(false);
  const [openGiveResultDialog, setOpenGiveResultDialog] = useState(false);
  const [openGenerateShippingLabelDialog, setOpenGenerateShippingLabelDialog] =
    useState(false);
  const [openPrintLCQRDialog, setOpenPrintLCQRDialog] = useState(false);
  const [openErpQRDialog, setOpenErpQRDialog] = useState({
    code: "",
    status: false,
  });
  const [externalUrl, setExternalUrl] = useState("");

  useEffect(() => {
    const step = data && data?.find((el) => el.status === "accepted");
    if (step) {
      setExternalUrl(step[0]?.data?.external_url);
    }
  }, [data]);

  useEffect(() => {
    if (data && isEqual(data[data.length - 1].status, "arrived")) {
      setNewData(
        [...data].concat({
          courier_name: "",
          data: null,
          data_type: "",
          date_time: new Date(),
          receiver: "system",
          status: "Product Correct?",
          tracking_code: "",
        })
      );
    } else if (data && isEqual(data[data.length - 1].status, "accepted")) {
      setNewData(
        [...data].concat({
          courier_name: "",
          data: null,
          data_type: "",
          date_time: new Date(),
          receiver: "system",
          status: "Product Defect?",
          tracking_code: "",
        })
      );
    } else if (data) {
      setNewData(data);
    }
  }, [data]);

  useEffect(() => {
    if (newData) {
      setActiveStep(newData?.length - 1);
    }
  }, [newData, data]);

  const handleNext = () => {
    setActiveStep((prevActiveStep) => prevActiveStep + 1);
  };

  const handleDefect = (isDefect: boolean) => {
    setIsDefect(isDefect);
    if (!isDefect) {
      setOpenDefectDialog(true);
    }
  };

  const { mutate: mutateReadyForLC, isLoading: loadReadyForLC } =
    useMutateReadyForLegitCheck(params?.code, handleNext);

  const { mutate: mutateCreateLC, isLoading: loadCreateLC } =
    useMutateCreateLegitCheck(params?.code);

  const { mutate: mutateUpdateLC, isLoading: loadUpdateLC } =
    useMutateUpdateLegitCheck(params?.code);

  const {
    mutate: mutateReadyShippingToBuyer,
    isLoading: loadReadyShippingToBuyer,
  } = useMutateReadyShippingToBuyer(params?.code, handleNext);

  const { mutate: mutateReceipt, isLoading: loadReceipt } = useMutateReceipt(
    params?.code
  );
  const { mutate: mutatePrintLabel, isLoading: loadPrintLabel } =
    useMutatePrintShippingLabel();

  const handleUploadLcImage = () => {
    if (lcImage && !isEmpty(dataTransaction?.legit_check)) {
      mutateUpdateLC({
        images: lcImage.file || [lcImage],
        id: dataTransaction?.legit_check?.id || 0,
      });
    } else if (!lcImage) {
      ErrorHandler("#ed4846", "Legit check images is required!");
    } else if (!lcImage.file && !Array.isArray(lcImage)) {
      ErrorHandler("#ed4846", "Minimum of legit check images is 10 img!");
    } else if (lcImage?.file.length < 10) {
      ErrorHandler("#ed4846", "Minimum of legit check images is 10 img!");
    } else if (lcImage?.file.length >= 10) {
      mutateCreateLC({
        images: lcImage.file,
        bnib_transaction_id: dataTransaction?.id || 0,
      });
    }
  };

  const handleUploadCorrectImage = () => {
    if (!correctImage) {
      ErrorHandler("#ed4846", "Image is required!");
    }
    mutateReceipt({
      images: correctImage.file || [correctImage],
    });
  };

  const handleEditLcDetail = (id: number) => {
    setOpenAddLcDetailDialog(true);
    setLcDetailId(id);
    setLcDetailType("edit");
  };

  const handleAddLcDetail = () => {
    setOpenAddLcDetailDialog(true);
    setLcDetailType("add");
  };

  const setOpenTrackDialog = () => {
    TransactionDispatch({
      type: ActionType.ShowTrackProduct,
      payload: {
        status: !openTrackDialog.status,
        code: "",
      },
    });
  };

  return (
    <main className="sm:pt-28 py-16">
      <div className="fixed top-0 z-30">
        <NavDetail
          title1="Transaction"
          title2="Process"
          handleBack={() => history.goBack()}
        />
      </div>

      {openErpQRDialog.status && (
        <OprintLCQR
          code={openErpQRDialog.code}
          isOpen={openErpQRDialog.status}
          setOpen={() => setOpenErpQRDialog({ status: false, code: "" })}
          title=""
        />
      )}

      {openTrackDialog.status && <OtrackProduct />}

      {/* <OdisputeBnibTransaction
        handleNext={handleNext}
        isOpen={openDisputeDialog}
        setOpen={setOpenDisputeDialog}
      /> */}

      {/* <OacceptBnibTransaction
        handleNext={handleNext}
        isOpen={openAcceptDialog}
        setOpen={setOpenAcceptDialog}
      /> */}

      {/* <OrejectBnibTransaction
        handleNext={handleNext}
        isOpen={openRejectDialog}
        setOpen={setOpenRejectDialog}
      /> */}
      {openDefectDialog && (
        <OdefectBnibTransaction
          handleNext={handleNext}
          isOpen={openDefectDialog}
          setOpen={setOpenDefectDialog}
          payload={{ is_defect: isDefect, defect_images: defectImage }}
        />
      )}
      {!isEmpty(dataTransaction?.legit_check) && (
        <OcreateLegitCheckDetail
          isOpen={openAddLcDetailDialog}
          setOpen={setOpenAddLcDetailDialog}
          legit_check_id={dataTransaction?.legit_check.id || 0}
          legit_check_detail_id={lcDetailId}
          type={lcDetailType}
        />
      )}
      <OgiveResultLegitCheck
        handleNext={handleNext}
        isOpen={openGiveResultDialog}
        setOpen={setOpenGiveResultDialog}
        legitCheck={dataLC}
      />

      {dataTransaction && openGenerateShippingLabelDialog && (
        <OgenerateShippingLabel
          handleNext={handleNext}
          isOpen={openGenerateShippingLabelDialog}
          setOpen={setOpenGenerateShippingLabelDialog}
          courierDetail={
            dataTransaction?.bnib_buy_order?.shipping_rate_courier_detail
          }
          status={dataTransaction.status}
        />
      )}

      {openPrintLCQRDialog && (
        <OprintLCQR
          code={dataTransaction?.bnib_buy_order_invoice_code}
          isOpen={openPrintLCQRDialog}
          setOpen={setOpenPrintLCQRDialog}
        />
      )}

      {LoadTransaction || isLoading ? (
        <div className="flex w-full justify-center mt-20">
          <Aloading />
        </div>
      ) : (
        <aside className="sm:flex gap-10">
          <section className="sm:w-1/4 bg-white shadow sm:min-h-screen sm:h-full h-fit p-5 text-sm">
            <aside className="flex justify-center mt-4">
              <img
                src={dataTransaction?.product_detail?.display_image_url}
                alt="-"
                className="w-28 bg-slate-100 rounded-md shadow"
              />
            </aside>
            <aside className="text-textPrimary mt-8">
              <h1>{dataTransaction?.product_detail.name}</h1>
              <p className="font-rom mt-1 text-xs">
                {dataTransaction?.product_detail.code} |{" "}
                {dataTransaction?.pre_order ? "Pre Order" : "BNIB"} |{" "}
                {dataTransaction?.product_detail.product_size.display_size}
              </p>
              <div className="font-rom mt-1 text-xs">
                <p>Seller : {dataTransaction?.seller_username}</p>
              </div>
              <hr className="mt-4" />
            </aside>

            <aside className="mt-4 text-textPrimary">
              <div className="flex justify-between">
                <h1>Location</h1>
                <h1
                  onClick={() =>
                    history.push(`/marketplace/transaction/${params.code}/scan`)
                  }
                  className="text-primary cursor-pointer"
                >
                  Scan QR
                </h1>
              </div>

              <span className="font-rom mt-1 text-xs">{`${
                dataTransaction &&
                dataTransaction?.location?.stock_move_location_id[1]
              } - ${
                dataTransaction &&
                dataTransaction?.location?.stock_move_location_dest_id[1]
              }`}</span>

              <hr className="mt-4" />
            </aside>
          </section>
          <section className="sm:w-3/4">
            <aside className="sm:block hidden">
              <DynamicBreadcrumb data={transactionRoute(params?.code)} />
              <hr className="mt-5" />
            </aside>

            <main className="w-full mb-10">
              <section className="mt-5">
                <article>
                  <h1 className="text-lg font-Bold">Transaction Process</h1>
                  <Stepper
                    activeStep={activeStep}
                    orientation="vertical"
                    style={{
                      padding: 0,
                      marginTop: "1rem",
                      marginBottom: "1rem",
                    }}
                  >
                    {newData?.map((step: any, idx: number) => (
                      <Step key={idx} expanded>
                        <StepLabel>
                          <aside className="font-med text-xs">
                            <div className="font-rom text-textSecondary flex justify-between items-center">
                              <p>
                                {step.receiver || "-"} |{" "}
                                {moment(step?.date_time).format("lll")}
                              </p>
                              {idx === 0 && (
                                <div className="flex gap-3">
                                  {dataTransaction &&
                                    dataTransaction.custom_tracking_enabled && (
                                      <p
                                        className="cursor-pointer hover:text-primary"
                                        onClick={() =>
                                          history.push(
                                            `/marketplace/transaction/${params.code}/tracking`
                                          )
                                        }
                                      >
                                        Custom Tracking
                                      </p>
                                    )}
                                  <p
                                    onClick={setOpenTrackDialog}
                                    className="cursor-pointer hover:text-primary"
                                  >
                                    Track product
                                  </p>
                                </div>
                              )}
                            </div>
                            <p className="text-textPrimary">
                              {renderStatusMessage(step.status)}
                            </p>
                          </aside>
                        </StepLabel>
                        <StepContent>
                          <Box>
                            {isEqual(idx, 0) && (
                              <Abutton
                                onClick={() =>
                                  setOpenErpQRDialog({
                                    code: step.data,
                                    status: true,
                                  })
                                }
                                title="Show ERP QR"
                              />
                            )}
                            {(isEqual(step.status, "seller-input-track") &&
                              isEqual(
                                newData[newData.length - 1].status,
                                "seller-input-track"
                              )) ||
                            (dataTransaction?.custom_tracking_enabled &&
                              dataTransaction?.current_office_custom_tracking_step_id &&
                              isEqual(newData.length - 1, idx) &&
                              isEqual(
                                dataTransaction?.status,
                                EBnibTransactionStatus.ShippingToDepatu
                              )) ? (
                              <div className="flex gap-3">
                                <Abutton
                                  title="Confirm Arrive"
                                  onClick={() =>
                                    history.push(
                                      `/marketplace/transaction/${params.code}/scan-arrive`
                                    )
                                  }
                                />
                                <Abutton
                                  title="Not Arrive"
                                  theme="secondary"
                                  // onClick={() => setOpenDisputeDialog(true)}
                                  onClick={() =>
                                    history.push(
                                      `/marketplace/transaction/${params.code}/scan-arrive`
                                    )
                                  }
                                />
                              </div>
                            ) : (
                              ""
                            )}

                            {pattern.test(step.status) && (
                              <div className="text-xxs flex flex-col gap-1">
                                <p>Track Record</p>
                                <Divider />
                                <span className="font-rom">{`${step.data.stock_move_location_id[1]} - ${step.data.stock_move_location_dest_id[1]}`}</span>
                              </div>
                            )}

                            {isEqual(step.status, "Product Correct?") &&
                              isEqual(
                                newData[newData.length - 1].status,
                                "Product Correct?"
                              ) && (
                                <div className="flex gap-3">
                                  <Abutton
                                    title="Correct"
                                    // onClick={() => setOpenAcceptDialog(true)}
                                    onClick={() =>
                                      history.push(
                                        `/marketplace/transaction/${params.code}/scan-correct#correct`
                                      )
                                    }
                                  />
                                  <Abutton
                                    title="Not Correct"
                                    theme="secondary"
                                    // onClick={() => setOpenRejectDialog(true)}
                                    onClick={() =>
                                      history.push(
                                        `/marketplace/transaction/${params.code}/scan-correct#not-correct`
                                      )
                                    }
                                  />
                                </div>
                              )}

                            {isEqual(step.status, "accepted") && (
                              <section>
                                <div className="md:w-1/2 w-full">
                                  <NewFilePonds
                                    setFiles={setCorrectImage}
                                    imageType={[
                                      "image/png",
                                      "image/jpg",
                                      "image/jpeg",
                                    ]}
                                    cropRatio="3:4"
                                    allowMultiple={true}
                                    maxFile={20}
                                    name="correct_images"
                                    maxFileSize="1mb"
                                  />
                                </div>
                                <div className="flex gap-3">
                                  <Abutton
                                    isLoading={loadReceipt}
                                    title="Upload"
                                    onClick={handleUploadCorrectImage}
                                  />
                                </div>

                                <aside className="flex flex-wrap gap-3 mt-3">
                                  {step?.data?.detail_path?.length > 0 &&
                                    step?.data?.detail_path?.map(
                                      (el: string, idx: number) => (
                                        <div
                                          key={idx}
                                          className="border shadow w-24 p-3 rounded-md"
                                        >
                                          <img
                                            src={el}
                                            alt="-"
                                            className="w-full"
                                          />
                                        </div>
                                      )
                                    )}
                                </aside>

                                <aside>
                                  <div className="w-full flex gap-1 items-center">
                                    <Input
                                      type="text"
                                      value={externalUrl}
                                      handleChange={(e: any) =>
                                        setExternalUrl(e.target.value)
                                      }
                                      placeholder="Input Drive Link"
                                      name="external_url"
                                    />
                                    <Abutton
                                      isLoading={loadReceipt}
                                      type="button"
                                      onClick={() =>
                                        mutateReceipt({
                                          external_url: externalUrl,
                                        })
                                      }
                                      title="Save"
                                    />
                                  </div>
                                </aside>
                              </section>
                            )}

                            {isEqual(step.status, "Product Defect?") &&
                              !isDefect &&
                              isEqual(
                                newData[newData.length - 1].status,
                                "Product Defect?"
                              ) && (
                                <div className="flex gap-3">
                                  <Abutton
                                    title="Defect"
                                    onClick={() => handleDefect(true)}
                                  />
                                  <Abutton
                                    title="Not Defect"
                                    theme="secondary"
                                    onClick={() => handleDefect(false)}
                                  />
                                </div>
                              )}

                            {isEqual(step.status, "Product Defect?") &&
                              isDefect &&
                              isEqual(
                                newData[newData.length - 1].status,
                                "Product Defect?"
                              ) && (
                                <section>
                                  <div className="md:w-1/2 w-full">
                                    <NewFilePonds
                                      setFiles={setDefectImage}
                                      imageType={[
                                        "image/png",
                                        "image/jpg",
                                        "image/jpeg",
                                      ]}
                                      cropRatio="3:4"
                                      allowMultiple={true}
                                      maxFile={20}
                                      name="defect_images"
                                      maxFileSize="1mb"
                                    />
                                  </div>
                                  <div className="flex gap-3">
                                    <Abutton
                                      title="Upload"
                                      onClick={() => setOpenDefectDialog(true)}
                                    />
                                  </div>
                                </section>
                              )}

                            {isEqual(step.status, "defect-true") && (
                              <section className="flex flex-wrap gap-3">
                                {newData[newData?.length - 1]?.data[0]
                                  .split(",")
                                  .map((el: string, idx: number) => (
                                    <div
                                      key={idx}
                                      className="border shadow w-24 p-3 rounded-md"
                                    >
                                      <img
                                        src={el}
                                        alt="-"
                                        className="w-full"
                                      />
                                    </div>
                                  ))}
                              </section>
                            )}

                            {(isEqual(step.status, "defect-true-hide") ||
                              isEqual(step.status, "defect-false")) && (
                              <>
                                <section className="flex flex-wrap gap-3">
                                  {isEqual(step.status, "defect-true-hide") &&
                                    newData[idx]?.data[0]
                                      .split(",")
                                      .map((el: string, idx: number) => (
                                        <div
                                          key={idx}
                                          className="border shadow w-24 p-3 rounded-md"
                                        >
                                          <img
                                            src={el}
                                            alt="-"
                                            className="w-full"
                                          />
                                        </div>
                                      ))}
                                </section>

                                {((isEqual(
                                  newData[newData.length - 1].status,
                                  "defect-true-hide"
                                ) &&
                                  !isEqual(
                                    dataTransaction?.status,
                                    EBnibTransactionStatus.DefectReject
                                  )) ||
                                  isEqual(
                                    newData[newData.length - 1].status,
                                    "defect-false"
                                  )) && (
                                  <div className="mt-5">
                                    <Abutton
                                      title="Start LC"
                                      isLoading={loadReadyForLC}
                                      onClick={() => mutateReadyForLC()}
                                    />
                                  </div>
                                )}
                              </>
                            )}

                            {isEqual(step.status, "ready-for-legit-check") && (
                              <section>
                                <div className="md:w-1/2 w-full">
                                  <NewFilePonds
                                    setFiles={setLcImage}
                                    imageType={[
                                      "image/png",
                                      "image/jpg",
                                      "image/jpeg",
                                    ]}
                                    cropRatio="3:4"
                                    allowMultiple={true}
                                    maxFile={20}
                                    name="lc_images"
                                    maxFileSize="1mb"
                                  />
                                </div>
                                <div className="flex gap-3">
                                  <Abutton
                                    isLoading={loadCreateLC || loadUpdateLC}
                                    title="Upload"
                                    onClick={handleUploadLcImage}
                                  />
                                </div>

                                <aside className="flex flex-wrap gap-3 mt-3">
                                  {!isEmpty(dataTransaction?.legit_check) &&
                                    dataTransaction?.legit_check.image_url
                                      .split(",")
                                      .map((el: string, idx: number) => (
                                        <div
                                          key={idx}
                                          className="border shadow w-24 p-3 rounded-md"
                                        >
                                          <img
                                            src={el}
                                            alt="-"
                                            className="w-full"
                                          />
                                        </div>
                                      ))}
                                </aside>

                                {!isEmpty(dataTransaction?.legit_check) && (
                                  <aside className="mt-5">
                                    <div>
                                      <Abutton
                                        title="Add LC Details"
                                        onClick={handleAddLcDetail}
                                      />
                                    </div>
                                    {loadLC ? (
                                      <div className="flex justify-center">
                                        <Aloading />
                                      </div>
                                    ) : (
                                      <div>
                                        <ul>
                                          {dataLC?.legit_check_detail &&
                                            dataLC?.legit_check_detail.length >
                                              0 && (
                                              <li className="mb-3 mt-5 font-med grid grid-cols-5 sm:text-sm text-xs">
                                                <p>Checker Initial</p>
                                                <p>Currency</p>
                                                <p>Price</p>
                                                <p>Result</p>
                                                <p>Actions</p>
                                              </li>
                                            )}
                                          {dataLC &&
                                            dataLC.legit_check_detail.map(
                                              (el, idx) => (
                                                <aside className="sm:text-xs text-xxs font-rom my-3">
                                                  <li
                                                    key={el.id}
                                                    className="mb-3 grid grid-cols-5"
                                                  >
                                                    <p>{el.checker_initial}</p>
                                                    <p className="uppercase">
                                                      {el.currency}
                                                    </p>
                                                    <p>
                                                      {formatRupiah(
                                                        el.price,
                                                        el.currency
                                                      )}
                                                    </p>
                                                    <p>{el.result}</p>
                                                    <p
                                                      onClick={() =>
                                                        handleEditLcDetail(
                                                          el.id
                                                        )
                                                      }
                                                      className="cursor-pointer hover:text-primary"
                                                    >
                                                      Edit
                                                    </p>
                                                  </li>
                                                  {idx !==
                                                    dataLC.legit_check_detail
                                                      .length -
                                                      1 && <Divider />}
                                                </aside>
                                              )
                                            )}
                                        </ul>
                                      </div>
                                    )}
                                  </aside>
                                )}

                                {!isEmpty(dataTransaction?.legit_check) &&
                                  isEqual(
                                    newData[newData.length - 1].status,
                                    "ready-for-legit-check"
                                  ) &&
                                  dataLC &&
                                  dataLC?.legit_check_detail.length > 0 && (
                                    <aside className="mt-5">
                                      <div className="flex gap-3">
                                        <Abutton
                                          title="Give Result"
                                          onClick={() =>
                                            setOpenGiveResultDialog(true)
                                          }
                                        />
                                      </div>
                                    </aside>
                                  )}
                              </section>
                            )}

                            {isEqual(step.status, "legit-check-authentic") &&
                              isEqual(
                                newData[newData.length - 1].status,
                                "legit-check-authentic"
                              ) && (
                                <section className="flex flex-wrap gap-3">
                                  <div className="">
                                    <Abutton
                                      model="long"
                                      title="Ready shipping to buyer"
                                      isLoading={loadReadyShippingToBuyer}
                                      onClick={() =>
                                        mutateReadyShippingToBuyer()
                                      }
                                    />
                                  </div>
                                </section>
                              )}

                            {(isEqual(step.status, "refund-payment-success") &&
                              isEqual(
                                newData[newData.length - 1].status,
                                "refund-payment-success"
                              ) &&
                              isEqual(
                                dataTransaction?.status,
                                EBnibTransactionStatus.BnibTransactionStatusReadyShippingForSeller
                              )) ||
                            isEqual(step.status, "ready-shipping-for-buyer") ? (
                              <section className="">
                                <div className="flex gap-2">
                                  <Abutton
                                    isDisabled={
                                      dataTransaction?.buyer_shipping_pickup_time
                                        ? true
                                        : false
                                    }
                                    theme={
                                      dataTransaction?.buyer_shipping_pickup_time
                                        ? "disabled"
                                        : "primary"
                                    }
                                    model="long"
                                    title="Generate shipping label"
                                    onClick={() =>
                                      setOpenGenerateShippingLabelDialog(true)
                                    }
                                  />
                                  {dataTransaction?.buyer_shipping_tracking_code && (
                                    <Abutton
                                      isLoading={loadPrintLabel}
                                      model="long"
                                      theme="secondary"
                                      title="Print shipping label"
                                      onClick={() =>
                                        mutatePrintLabel({
                                          code: dataTransaction?.buyer_shipping_tracking_code,
                                        })
                                      }
                                    />
                                  )}
                                  <Abutton
                                    model="long"
                                    theme="secondary"
                                    title="Print LC QR"
                                    onClick={() => setOpenPrintLCQRDialog(true)}
                                  />
                                </div>
                                {dataTransaction && (
                                  <aside className="my-3">
                                    <Divider />
                                  </aside>
                                )}
                                <div className="flex gap-4 sm:text-sm text-xs">
                                  <div className="flex gap-2 items-center">
                                    <p>Courier:</p>
                                    <div className="mb-1">
                                      <ATextField
                                        type="text"
                                        name="courier"
                                        isDisabled
                                        placeholder="Place Holder"
                                        value={
                                          dataTransaction?.bnib_buy_order
                                            ?.shipping_rate_courier_detail
                                            ?.logistic_name
                                        }
                                      />
                                    </div>
                                  </div>
                                  <div className="flex gap-2 items-center">
                                    <p>Pickup Time:</p>
                                    <div className="mb-1">
                                      <ATextField
                                        type="text"
                                        name="pickup_time"
                                        isDisabled
                                        placeholder="Place Holder"
                                        value={
                                          dataTransaction?.refund_shipping_pickup_time
                                            ? moment(
                                                dataTransaction?.refund_shipping_pickup_time
                                              ).format("lll")
                                            : dataTransaction?.buyer_shipping_pickup_time
                                            ? moment(
                                                dataTransaction?.buyer_shipping_pickup_time
                                              ).format("lll")
                                            : ""
                                        }
                                      />
                                    </div>
                                  </div>
                                </div>
                              </section>
                            ) : (
                              ""
                            )}
                          </Box>
                        </StepContent>
                      </Step>
                    ))}
                  </Stepper>
                </article>
              </section>
            </main>
          </section>
        </aside>
      )}
    </main>
  );
};

export default TransactionProcess;
