import { Divider } from "@material-ui/core";
import moment from "moment";
import React, { useContext, useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { image94, image95, image98 } from "../../../assets/img";
import { useFetchBnibTransactionTrack } from "../../../hooks/bnib_transaction";
import { renderStatusMessage } from "../../../pages/transaction/TransactionProcess";
import { ActionType } from "../../../store/transaction/action";
import TransactionContext from "../../../store/transaction/TransactionContext";
import ADialogBox from "../../atoms/ADialogBox";
import Loading from "../../loading/Loading";

const OtrackProduct: React.FC = () => {
  const params: { code: string } = useParams();
  const { TransactionState, TransactionDispatch } =
    useContext(TransactionContext);
  const { openTrackDialog } = TransactionState;
  const [newData, setNewData] = useState<any[]>([]);

  const { data, isLoading } = useFetchBnibTransactionTrack(
    openTrackDialog.code || params.code
  );

  const setOpenTrackDialog = () => {
    TransactionDispatch({
      type: ActionType.ShowTrackProduct,
      payload: {
        status: !openTrackDialog.status,
        code: "",
      },
    });
  };

  useEffect(() => {
    if (data) {
      setNewData(data);
    }
  }, [data]);
  
  return (
    <ADialogBox
      content={{
        title: "",
        body: (
          <>
            {isLoading ? (
              <main className="my-20 flex justify-center">
                <Loading />
              </main>
            ) : (
              <main className="flex flex-col mt-5">
                <section className="flex justify-between px-5">
                  <img src={image95} alt="-" className="w-14" />
                  <div className="border-t border-primary w-16 mt-5" />
                  <img src={image94} alt="-" className="w-14" />
                  <div className="border-t border-primary w-16 mt-5" />
                  <img src={image98} alt="-" className="w-14" />
                </section>
                <section className="flex flex-col items-center mt-4">
                  <h1 className="text-sm">Received by Buyer</h1>
                  {data && (
                    <p className="text-xs text-primary">
                      {newData[0]?.tracking_code || "-"}
                    </p>
                  )}
                  <Divider />
                </section>
                <section>
                  {newData.reverse().map((el, idx) => (
                    <aside key={idx} className="my-3">
                      {el.history && (
                        <div className="bg-primary rounded-full w-fit py-2 px-4 my-5">
                          <h1 className="text-white md:text-sm text-xs">
                            {el.tag.split("-").join(" ")}
                          </h1>
                        </div>
                      )}
                      <div>
                        {el.history &&
                          el.history.map(
                            (
                              v: {
                                status: string;
                                date_time: string;
                                receiver: string;
                              },
                              id: number
                            ) => (
                              <article>
                                <aside className="flex items-center gap-3 ml-2">
                                  <div className="bg-success rounded-full w-3 h-3" />

                                  <div key={id} className="">
                                    <p className="text-xs font-rom text-textSecondary">
                                      {moment(v.date_time).format("lll")}
                                    </p>
                                    <p
                                      className={
                                        "text-sm " +
                                        (id === 0
                                          ? "text-textPrimary"
                                          : "text-textSecondary")
                                      }
                                    >
                                      {renderStatusMessage(
                                        v.status.split("-").join(" ")
                                      )}
                                    </p>
                                    {v.receiver && (
                                      <p className="text-xs font-rom text-textSecondary">
                                        received by: {v.receiver}
                                      </p>
                                    )}
                                  </div>
                                </aside>
                                {el.history.length - 1 !== id && (
                                  <div className="h-6 ml-3.5 border-l" />
                                )}
                              </article>
                            )
                          )}
                      </div>
                    </aside>
                  ))}
                </section>
              </main>
            )}
          </>
        ),
      }}
      isOpen={openTrackDialog.status}
      setIsOpen={setOpenTrackDialog}
    />
  );
};

export default OtrackProduct;
